import style from './contact.module.css';
import { PopupButton } from "react-calendly";

export default function Contact() {
    return (
        <>
        <div className={style.bg}>
            <div className={style.overlay}>
                <h1 className={style.contact}>CONTACT </h1><h1 className={style.blink}>_</h1>
                    <div className={style.container}>
                            <p className={style.desc}>GET IN TOUCH WITH US</p>
                            <p className={style.info}>Email: <a rel="noopener noreferrer" target="_blank" href="mailto:info@nyte.works">info@nyte.works</a></p>
                            <p className={style.info}>Instagram: <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/nyte.works/">@nyte.works</a></p>
                            <p className={style.info}>YouTube: <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/@NYTE-WORKS">youtube.com/@NYTE-WORKS</a></p>
                            <p className={style.info}>TikTok: <a rel="noopener noreferrer" target="_blank" href="https://www.tiktok.com/@nyte.works">tiktok.com/@nyte.works</a></p>
                            <p className={style.book}>BOOK A MEETING WITH US</p>
                        <PopupButton
                        url="https://calendly.com/nyte-works/meeting"
                        rootElement={document.getElementById("root")}
                        text="CLICK HERE TO SCHEDULE"
                        />
                        </div>
                </div>
        </div>
        </>
    );
} 