import React, { useState } from 'react';
import style from './nav-items.module.css';
import { useRef } from 'react';
import {ReactComponent as MoonIcon} from '../resources/moon30px.svg';
import {ReactComponent as NyteIcon} from '../resources/nyte_works_new.svg';
import {useEffect} from 'react';
import {Link,
NavLink
} from "react-router-dom";

const useIsMounted = () => {
    const isMounted = useRef(false);
    useEffect(() => {
      isMounted.current = true;
      return () => isMounted.current = false;
    }, []);
    return isMounted;
  };

export default useIsMounted;
export function Moon(props) {
    const [animated, setAnimated] = useState(false);
    return ( 
    <div onMouseEnter={() => setAnimated(() => true)}
    onAnimationEnd={() => setAnimated(() => false)} 
    className={`${style.moon} ${animated ? `${style.spin}` : ''}`} >
        <Link to="/">
            <MoonIcon/>
        </Link>
    </div>
    );
}
export function Photo(props) {
    return (
    <NavLink to='/photography' 
    style={({ isActive, isPending }) => {
        return {
          backgroundColor: isActive ? "#383838" : "",
        };
      }}
    >
    <div className={`${style.buttons}`}>
            <p className={style.grow}>PHOTOGRAPHY</p>
    </div>
    </NavLink>
    );
}

export function Nyte() {
    return (
        <div className={style.nyte}>
            <NyteIcon/>
        </div>
    );
}
export function Video(props) {
    return (
        <NavLink to='/videography' 
    style={({ isActive, isPending }) => {
        return {
          backgroundColor: isActive ? "#383838" : "",
        };
      }}
    >
        <div className={`${style.buttons}`}>
            <p className={style.grow}>VIDEOGRAPHY</p>
        </div>
        </NavLink>
    );
}
export function Mission(props) {
    return (
        <NavLink to='/mission' 
        style={({ isActive, isPending }) => {return {backgroundColor: isActive ? "#383838" : "",};}}>
            <div className={`${style.buttons}`}>
                <p className={style.grow}>OUR MISSION</p>
            </div>
        </NavLink>
    );
}
export function Contact(props) {
    return (
        <NavLink to='/contact' 
        style={({ isActive, isPending }) => {return {backgroundColor: isActive ? "#383838" : "",};}}>
            <div className={`${style.buttons}`}>
                <p className={style.grow}>CONTACT</p>
            </div>
        </NavLink>
    );
}