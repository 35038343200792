import React from 'react';
import Navbar from './components/navbar/navbar';
import { Outlet, 
} from 'react-router-dom';
import style from './root.module.css';

export default function Root() {
    return (
      <>
        {/*SPACER*/}
        <div className={style.spacer}/>
    {/*NAVBAR*/}
        <Navbar/>
    {/*OUTLET*/}
        <Outlet>
          
        </Outlet>
      </>
    );
}