import React from 'react';
import style from './mission.module.css';
import anthony from './components/resources/images/portraits/anthony.jpg';
import tony from './components/resources/images/portraits/tony.jpg';
import cody from './components/resources/images/portraits/cody.jpg';
import thomas from './components/resources/images/portraits/thomas.jpg';
import danny from './components/resources/images/portraits/danny.jpg';

export default function Mission() {
    return (
        <>
        <h1 className={style.mission}>ABOUT US </h1><h1 className={style.blink}>_</h1>
        <p className={style.desc}>NYTE WORKS is a tight-knit production team of five friends founded in 2022. We are passionate about fostering relationships with emerging artists in an environment that cultivates growth. Our mission is to build a platform that empowers artists to showcase their creativity and voices through visual narratives.</p>
        <div className={style.container}>
            <div className={style.profcontainer}>
                <img src={tony} className={style.propic}></img>
                <p className={style.name}>Tony Tat</p>
                <p className={style.job}>CEO & Visual Effects</p>
                <p className={style.bio}>Having surrounded himself with many talented artists within Boston, Tony Tat founded NYTE.WORKS as an outlet for creatives to express themselves
                through visual arts. Years of experience within a multitude of practices such as visual effects, cinematography, and music allow him to constantly strive for excellence.
                He is passionate about allowing the freedom of expression through storytelling and works behind the scenes to help the artists convey their visions by
                pushing the boundries of what is possible.</p>
            </div>
            <div className={style.profcontainer}>
                <img src={anthony} className={style.propic}></img>
                <p className={style.name}>Anthony Li</p>
                <p className={style.job}>Director of Photography</p>
                <p className={style.bio}>Anthony Li is a visual artist born and raised in Boston, MA. 
                With years of masterful experience in the art of photography, he expertly breathes life into every story through his captivating cinematography.
                He has worked closely with overseas artists such as CAMO, JUNNY, and GEMINI with portraits and concert visuals being featured on Spotify. 
                He continues to develop his work as an aspiring Director of Photography and Colorist for NYTE.WORKS.</p>
            </div>
            <div className={style.profcontainer}>
                <img src={cody} className={style.propic}></img>
                <p className={style.name}>Cody Tu</p>
                <p className={style.job}>Lead Graphic Designer</p>
                <p className={style.bio}>Cody Tu is a multi-disciplinary designer and photographer.
He started out making sports graphics and doing sneaker photography, but grew to love urban exploration and later on, portraiture.
 He has been featured in Complex Magazine, Billboard Magazine, the Boston Voyager, HotNewHipHop, and Meet The Creators.
                </p>
            </div>
            <div className={style.profcontainer}>
                <img src={thomas} className={style.propic}></img>
                <p className={style.name}>Thomas Nguyen</p>
                <p className={style.job}>Concert & Portrait Photographer</p>
                <p className={style.bio}>Thomas Nguyen is a portrait/concert photographer based in Boston.
                He started photography beginning of 2022 and quickly grew a love for it. 
                He has been on tour as pop star, Andi Mitchell's exclusive photographer on her Rulebook Tour. 
                He now has found a passion towards fashion/editorial photography and has worked with many artists/models in the greater Boston area.</p>
            </div>
            <div className={style.profcontainer}>
                <img src={danny} className={style.propic}></img>
                <p className={style.name}>Danny Nguyen</p>
                <p className={style.job}>Director of Videography</p>
                <p className={style.bio}>Danny Nguyen is a music video director based in Boston. 
                He introduced himself into the music scene by filming and photographing mainstream artists like Trippie Redd, Ericdoa, and Polo G at their concerts. 
                Later on, he garnered the attention of many Boston 
                artists to execute innovative concepts with each music video that he has done and continues to develop.</p>
            </div>
            
        </div>
        </>
    );
}