import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import Hamburger from 'hamburger-react';
import styles from './navbar.module.css';
import style from '../nav-items/nav-items.module.css';
import '../nav-items/nav-items.js';
import { Photo, Video, Mission, Contact, Moon, Nyte} from '../nav-items/nav-items.js';

const Navbar = (props) => {
    const [isOpen, setOpen] = useState(false);
    const nodeRef = useRef(null);
    const isMobile = useMediaQuery({ maxWidth: 750 });
    return (
    <><nav>
            <Moon />
            {isMobile === false && <Photo />}
            {isMobile === true && <Nyte />}
            {isMobile === false && <Video />}
            {isMobile === false && <Mission />}
            {isMobile === false && <Contact />}
            {isMobile === true && <div className={style.burger}>
                <Hamburger toggled={isOpen} toggle={setOpen} direction="left" color="#dddaca" rounded size={30} duration={0.2} />
            </div>}
        </nav>
        {isMobile === true && <div className={isOpen ? styles.dropdown : styles.hidden}>
            <Photo />
            <Video />
            <Mission />
            <Contact />
            </div>}
    </>
    );
}
export default Navbar;
