import React from 'react';
import style from './home.module.css';
import './components/resources/fonts/stylesheet.css';
import video from './components/resources/video/short-web.mp4'
import background from './components/resources/video/background.mp4'
import gryffin from './components/resources/images/gryffin.jpg'
import nav from './components/resources/images/nav.jpg'
import badbunny from './components/resources/images/badbunny.jpg'
import {NavLink} from 'react-router-dom';

function Home() {
  return (
    <>
{/* NYTE WORKS INTRO*/}
    <div className={`${style.introcontainer}`}>
      <div className={`${style.preview}`}>
        <video playsInline autoPlay muted loop>
            <source src={background} type="video/mp4"/>
          </video>
      </div>
      <div className={`${style.overlay}`}>
              <div className={`${style.more}`}>SEE MORE
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.58 14.93" width="1.4dvh">
            <path d="M.84,7.29l6.45,6.44,6.44-6.44" transform="translate(0.01 0.01)" fill="none" stroke="#dddaca" stroke-linecap="round" stroke-width="1.69"/>
            <path d="M.84.84,7.29,7.29,13.73.84" transform="translate(0.01 0.01)" fill="none" stroke="#dddaca" stroke-linecap="round" stroke-width="1.69"/>
          </svg>
        </div>
        <p className={`${style.intro}`}>
          NYTE WORKS<br></br>IS A CREATIVE PRODUCTION<br></br>COMPANY<br></br>BASED IN BOSTON. 
        </p>
      </div>
    </div>

{/* VIDEO PREVIEWS */}
    <div className={`${style.previewvid}`}>
      <video playsInline autoPlay muted loop>
        <source src={video} type="video/mp4"/>
      </video>
    </div>

{/* PICTURE PREVIEWS */}
    <div className={`${style.container}`}>
      <img src={gryffin}/>
      <img src={nav}/>
      <img src={badbunny}/>
    </div>
{/* GET TO KNOW US */}
    <div className={`${style.footer}`}>
    <NavLink to='/contact' 
        style={({ isActive, isPending }) => {return {backgroundColor: isActive ? "#383838" : "",};}}>
              <p className={`${style.contact}`}>GET TO KNOW US ✉</p>
        </NavLink>
    </div>

{/* END */}
    </>
  );
}

export default Home;
