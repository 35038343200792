import React from 'react';
import style from './image.module.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

function ImageComponent(props) {
    return (
        <div className={`${style.container}`}>
            <LazyLoadImage 
            src={props.src}
            placeholderSrc={props.psrc}
            effect="blur"/>
            <div className={`${style.overlayDesc}`}>
                <div className={`${style.desc}`}>{props.desc}</div>
            </div>
            <div className={`${style.overlayAuthor}`}>
                <div className={`${style.desc}`}>{props.author}</div>
            </div>
        </div>
    );
  }
  
  export default ImageComponent;