import React from 'react';
import style from './video.module.css';

function Video() {
  return (
    <div className={style.container}>
      <div className={style.video}>
        <iframe src="https://player.vimeo.com/video/912754239?transparent=0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Say Something"></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>      
      <div className={style.video}>
        <iframe src="https://player.vimeo.com/video/912828185?transparent=0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Tia Corine Recap"></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <div className={style.video}>
        <iframe src="https://player.vimeo.com/video/912753059?transparent=0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Brand New"></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <div className={style.video}>
        <iframe src="https://player.vimeo.com/video/912716681?transparent=0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Lights"></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <div className={style.video}>
        <iframe src="https://player.vimeo.com/video/912629729?transparent=0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Ringtone"></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <div className={style.video}>
        <iframe src="https://player.vimeo.com/video/912463655?transparent=0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Trippie Redd Concert Recap"></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <div className={style.video}>
        <iframe src="https://player.vimeo.com/video/854243117?transparent=0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Ambitions of A Rider"></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <div className={style.video}>
        <iframe loading="lazy" src="https://player.vimeo.com/video/847453686?transparent=0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Laughing To The Bank"></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <div className={style.video}>
        <iframe src="https://player.vimeo.com/video/854244026?transparent=0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Ambitions of A Rider"></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <div className={style.video}>
        <iframe loading="lazy" src="https://player.vimeo.com/video/855878893?transparent=0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Laughing To The Bank"></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <div className={style.video}>
        <iframe src="https://player.vimeo.com/video/847440523?transparent=0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Ambitions of A Rider"></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <div className={style.video}>
        <iframe loading="lazy" src="https://player.vimeo.com/video/847445594?transparent=0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Laughing To The Bank"></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>

    </div>
  );
}

export default Video;
