import React from 'react';
import style from './photo.module.css';
import ImageComponent from './components/image';
import * as image from './components/resources/images/index.js';

function Photo() {
  return (
    <div className={style.container}>
      <h1 id={style.photo}>PHOTOGRAPHY</h1> <h1 className={style.blink}>_</h1>
      <div className={style.gallery}>
        <ImageComponent src={image.img1} psrc={image.pimg1}
        desc="Pallete Wear Product Shoot"
        author="Thomas Nguyen"/>
        <ImageComponent src={image.img2} psrc={image.pimg2}
        desc="NAV Concert @nav"
        author="Thomas Nguyen"/>
        <ImageComponent src={image.img3} psrc={image.pimg3}
        desc="Bad Bunny Concert @badbunnypr"
        author="Thomas Nguyen"/>
        <ImageComponent src={image.img4} psrc={image.pimg4}
        desc="BLXST Graphic Art"
        author="Anthony Li"/>
        <ImageComponent src={image.img5} psrc={image.pimg5}
        desc="CAMO @camokr"
        author="Anthony Li"/>
        <ImageComponent src={image.img6} psrc={image.pimg6}       
        desc="DPR Ian @dprian"
        author="Anthony Li"/>
        <ImageComponent src={image.img7} psrc={image.pimg7}       
        desc="DPR Live @dprlive"
        author="Anthony Li"/>
        <ImageComponent src={image.img8} psrc={image.pimg8}
        desc="Kleo @kleo2020"
        author="Thomas Nguyen"/>
        <ImageComponent src={image.img9} psrc={image.pimg9}
        desc="Brooke Charlese @brookecharlese"
        author="Thomas Nguyen"/>
        <ImageComponent src={image.img10} psrc={image.pimg10}
        desc="Y2K Photoshoot"
        author="Thomas Nguyen"/>
        <ImageComponent src={image.img11} psrc={image.pimg11}
        desc="Tatiana White @tatiana.white"
        author="Thomas Nguyen"/>
        <ImageComponent src={image.img12} psrc={image.pimg12}
        desc="DPR Ian @dprian"
        author="Anthony Li"/>
        <ImageComponent src={image.img13} psrc={image.pimg13}
        desc="BLXST Concert"
        author="Anthony Li"/>
        <ImageComponent src={image.img14} psrc={image.pimg14}
        desc="Smokepurrp Show"
        author="Anthony Li"/>
        <ImageComponent src={image.img15} psrc={image.pimg15}
        desc="Talk Album Cover"
        author="Cody Tu"/>
        <ImageComponent src={image.img16} psrc={image.pimg16}
        desc="Trippie Redd Show"
        author="Anthony Li"/>
      </div>
    </div>
  );
}

export default Photo;
